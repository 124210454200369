// https://mui.com/customization/theme-components/

const components = {
    MuiButton: {
        variants: [
            {
                props: { variant: 'primary' },
                style: {
                    border: '1px solid white',
                    borderRadius: '0px',
                    color: 'white',
                    fontFamily: [
                        'museo-sans',
                        'Source Sans Pro',
                        'sans-serif',
                    ].join(','),
                    fontSize: '14px',
                    fontWeight: '900',
                    padding: '12px 36px',
                    textDecoration: 'none',
                    textTransform: 'uppercase',
                    '&:hover': {
                        backgroundColor: 'white',
                        color: 'black',
                    },
                },
            },
            {
                props: { variant: 'secondary' },
                style: {
                    border: '1px solid #C20122',
                    backgroundColor: '#C20122',
                    borderRadius: '0px',
                    color: 'white',
                    fontFamily: [
                        'museo-sans',
                        'Source Sans Pro',
                        'sans-serif',
                    ].join(','),
                    fontSize: '14px',
                    fontWeight: '900',
                    padding: '12px 36px',
                    textDecoration: 'none',
                    textTransform: 'uppercase',
                    width: '100%',
                    '&:hover': {
                        backgroundColor: 'white',
                        color: '#C20122',
                    },
                },
            },
            {
                props: { variant: 'print' },
                style: {
                    border: '1px solid #464646',
                    borderRadius: '0px',
                    color: '#464646',
                    fontFamily: [
                        'museo-sans',
                        'Source Sans Pro',
                        'sans-serif',
                    ].join(','),
                    fontSize: '14px',
                    fontWeight: '900',
                    padding: '12px 36px',
                    textDecoration: 'none',
                    textTransform: 'uppercase',
                    '&:hover': {
                        backgroundColor: '#464646',
                        color: 'white',
                    },
                },
            },
            {
                props: { variant: 'primaryFooter' },
                style: {
                    border: '1px solid white',
                    borderRadius: '0px',
                    color: 'white',
                    fontFamily: [
                        'museo-sans',
                        'Source Sans Pro',
                        'sans-serif',
                    ].join(','),
                    fontSize: '14px',
                    fontWeight: '900',
                    padding: '8px 36px',
                    textTransform: 'uppercase',
                    '&:hover': {
                        backgroundColor: 'white',
                        color: 'black',
                    },
                },
            },
            {
                props: { variant: 'primaryNav' },
                style: {
                    border: '1px solid white',
                    borderRadius: '0px',
                    color: 'white',
                    fontFamily: [
                        'museo-sans',
                        'Source Sans Pro',
                        'sans-serif',
                    ].join(','),
                    fontSize: '14px',
                    fontWeight: '900',
                    letterSpacing: '2px',
                    padding: '12px 18px',
                    textDecoration: 'none',
                    textTransform: 'uppercase',
                    '&:hover': {
                        backgroundColor: 'white',
                        color: 'black',
                    },
                    '&.active': {
                        backgroundColor: 'white',
                        color: 'black',
                    },
                    width: '170px',
                    '@media (max-width:1200px)': {
                        width: '124px',
                        fontSize: '12px',
                    },
                    '@media (max-width:992px)': {
                        width: '180px',
                    },
                },
            },
        ],
    },
    MuiGrid: {
        variants: [
            {
                props: { variant: 'container' },
                style: {
                    maxWidth: '1400px',
                    width: 'auto',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                },
            },
        ],
    },
    MuiCssBaseline: {
        styleOverrides: {
            html: {
                overflowX: 'hidden',
                overflowY: 'scroll',
            },
            body: {
                overflowX: 'hidden',
                width: '100%',
            },
            h1: {
                fontWeight: 600,
                fontSize: '44px',
                letterSpacing: '2px',
            },
            h2: {
                fontWeight: 400,
                fontFamily: [
                    'museo',
                    'Source Sans Pro',
                    'sans-serif',
                ].join(','),
                fontSize: '26px',
                '@media (max-width:768px)': {
                    fontSize: '20px',
                },
            },
            h3: {
                fontWeight: 600,
                fontSize: '20px',
                letterSpacing: '4px',
            },
            h4: {
                fontWeight: 600,
                fontSize: '18px',
                letterSpacing: '2px',
                fontFamily: [
                    'museo-sans',
                    'Source Sans Pro',
                    'sans-serif',
                ].join(','),
            },
            h5: {
                fontWeight: 600,
                fontSize: '16px',
                letterSpacing: '3px',
                fontFamily: [
                    'museo-sans',
                    'Source Sans Pro',
                    'sans-serif',
                ].join(','),
            },
            a: {
                textDecoration: 'none',
                '.navLink': {
                    position: 'relative',
                    textDecoration: 'none !important',
                    textTransform: 'uppercase',

                    ':after': {
                        content: '\'\'',
                        position: 'absolute',
                        width: '100%',
                        transform: 'scaleX(0)',
                        height: '2px',
                        bottom: '-2px',
                        left: 0,
                        backgroundColor: '#464646',
                        transformOrigin: 'bottom left',
                        transition: 'transform 0.25s ease-out',
                    },
                    ':hover:after': {
                        transform: 'scaleX(1)',
                        transformOrigin: 'bottom left',
                    },
                },
            },

            p: {
                color: '#464646',
                a: {
                    color: '#464646',
                    fontWeight: 900,
                    fontSize: '14px',
                    letterSpacing: '2px',
                    position: 'relative',
                    textDecoration: 'none !important',
                    textTransform: 'uppercase',

                    ':after': {
                        content: '\'\'',
                        position: 'absolute',
                        width: '100%',
                        transform: 'scaleX(0)',
                        height: '2px',
                        bottom: '-2px',
                        left: 0,
                        backgroundColor: '#464646',
                        transformOrigin: 'bottom left',
                        transition: 'transform 0.25s ease-out',
                    },
                    ':hover:after': {
                        transform: 'scaleX(1)',
                        transformOrigin: 'bottom left',
                    },
                },
            },
        },
    },
}

export default components
