import { getApp } from 'firebase/app'
import { httpsCallable, getFunctions } from 'firebase/functions'
export const firebaseFunctionCall = (callable) => {
    if (typeof window !== 'undefined') {
        const functions = getFunctions(getApp(), 'us-central1')
        const fbFunction = httpsCallable(functions, callable)
        return fbFunction
    }
    return null
}
