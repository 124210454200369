// https://mui.com/customization/typography/

const typography = {
    fontFamily: [
        'museo',
        'Source Sans Pro',
        'sans-serif',
    ].join(','),
    fontSize: 12,
    fontWeight: 400,
    color: 'black.main',
    h1: {
        fontWeight: 600,
        fontSize: '40px',
        letterSpacing: '0px',
    },
    h1alt: {
        fontSize: '44px',
        letterSpacing: '2px',
        fontFamily: [
            'adobe-garamond-pro',
        ],
    },
    h2: {
        fontWeight: 400,
        fontSize: '26px',
        '@media (max-width:768px)': {
            fontSize: '20px',
        },
    },
    h3: {
        fontWeight: 600,
        fontSize: '20px',
        letterSpacing: '4px',
    },
    h4: {
        fontWeight: 600,
        fontSize: '18px',
        letterSpacing: '2px',
        fontFamily: [
            'museo-sans',
            'Source Sans Pro',
            'sans-serif',
        ].join(','),
    },
    h5: {
        fontWeight: 600,
        fontSize: '16px',
        letterSpacing: '3px',
        fontFamily: [
            'museo-sans',
            'Source Sans Pro',
            'sans-serif',
        ].join(','),
    },
    h6: {
        fontWeight: 600,
        fontSize: '14px',
        letterSpacing: '3px',
    },
    body1: {
        fontSize: '20px',
        '@media (max-width:768px)': {
            fontSize: '16px',
            lineHeight: '20px',
        },
        fontWeight: 300,
        fontFamily: [
            'museo-sans',
            'Source Sans Pro',
            'sans-serif',
        ].join(','),
        lineHeight: '25px',
    },
    feed: {
        fontWeight: 600,
        fontSize: '25px',
        fontFamily: [
            'museo-sans',
            'Source Sans Pro',
            'sans-serif',
        ].join(','),
        '@media (max-width:768px)': {
            fontSize: '20px',
        },
        lineHeight: '27px',

    },
    nav1: {
        color: 'white',
        fontSize: 14,
        fontWeight: 900,
        letterSpacing: '2px',
    },
    nav2: {
        color: 'white',
        fontSize: 14,
        fontWeight: 700,
        letterSpacing: '2px',
    },
    link: {
        fontSize: '26px',
        '@media (max-width:768px)': {
            fontSize: '23px',
        },
        fontWeight: 300,
        letterSpacing: '0px',
    },
    blockquote: {
        fontFamily: [
            'museo-sans',
            'Source Sans Pro',
            'sans-serif',
        ].join(','),
        fontSize: '32px',
        fontWeight: 300,
        lineHeight: '35px',
    },
}

export default typography
