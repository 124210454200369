// https://mui.com/customization/palette/

const palette = {
    black: {
        main: '#464646',
        lite: '#333333',
        pure: '#000000',
        contrastText: '#fff',
    },
    white: {
        main: '#FFFFFF',
        off: '#EEEDEB',
    },
    grey: {
        main: '#D6D3CE',
        lite: '#E5E4DB',
        dark: '#707070',
        darkest: '#4E5055',
    },
    clay: {
        main: '#AFA99F',
        dark: '#AEA89E',
    },
    red: {
        main: '#C20122',
        lite: '#CE132D',
    },
}

export default palette
