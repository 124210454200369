import { createTheme } from '@mui/material/styles'
import breakpoints from './breakpoints'
import components from './components'
import palette from './palette'
import space from './space'
import spacing from './spacing'
import typography from './typography'

export default createTheme({
    breakpoints,
    components,
    palette,
    space,
    spacing,
    typography,
})
