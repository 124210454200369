import spacing from './spacing'

const space = []
space.xxs = `${spacing*1}px` // 6px
space.xs = `${spacing*2}px` // 12px
space.sm = `${spacing*3}px` // 18px
space.md = `${spacing*5}px` // 30px
space.lg = `${spacing*8}px` // 48px
space.xl = `${spacing*10}px` // 60px
space.xxl = `${spacing*12}px` // 72px
space.xxxl = `${spacing*14}px` // 84px
space.xxxxl = `${spacing*15}px` // 90px

export default space
