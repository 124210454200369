// https://mui.com/customization/breakpoints/

const breakpoints = {
    values: {
        xxxs: 320,
        xxs: 374,
        xs: 480,
        sm: 576,
        md: 768,
        lg: 992,
        xl: 1140,
        xxl: 1400,
        xxxl: 1684,
        mobile: 0,
        tablet: 640,
        laptop: 1024,
        desktop: 1200,
        twoK: 1900,
        fourK: 3800,

    },
}

export default breakpoints
