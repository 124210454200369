import { configureStore } from '@reduxjs/toolkit'
import Globals from './reducers/globals'
import Preview from './reducers/preview'
import Auth from './reducers/auth'
import User from './reducers/user'
import Dialog from './reducers/dialog'

export const store = configureStore({
    reducer: {
        Auth,
        Globals,
        User,
        Dialog,
        Preview,
    },
})
